import React from 'react';
import { Navigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

export const PrivateRoutePriceTags = ({ Component }) => {
    const currentUserToken = localStorage.getItem('token');

    if (!currentUserToken) {
        return <Navigate to='/login' />;
    }

    const jwtData = jwt_decode(currentUserToken);

    if (!jwtData.userId) {
        return <Navigate to='/login' />;
    }

    const { role, settingsArray } = jwtData;

    // Default to true for ADMIN role
    let modulePriceCards = true;
    let moduleDigitalFlyers = true;

    if (role !== 'ADMIN') {
        const priceCardsModule = settingsArray.find(module => module.moduleSlug === 'price_cards');
        modulePriceCards = priceCardsModule ? priceCardsModule.showToUser : false;

        const digitalFlyersModule = settingsArray.find(module => module.moduleSlug === 'digital_flyers');
        moduleDigitalFlyers = digitalFlyersModule ? digitalFlyersModule.showToUser : false;
    }

    // Check permissions based on the user's role and module settings
    if (!modulePriceCards || !moduleDigitalFlyers) {
        return <Navigate to='/' />;
    }

    // Render the component if all conditions are met
    return <Component />;
};